



































































































































































































































import store, { useUserGetters, useUserActions } from '@/store';
import _ from 'lodash';
import { ActionTypes } from '@/store/modules/tools/actions';
import { computed, defineComponent, ref, watch, onMounted } from '@vue/composition-api';

export default defineComponent({
  name: 'MProgramCheckoutQualifications',
  components: {
    AAutocomplete: () => import('@/components/atoms/AAutocomplete.vue')
  },
  props: {
    programId: {
      type: String,
      required: true
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    openCheckoutView: {
      type: Boolean,
      default: false,
      required: false
    },
    isTemplate: {
      type: Boolean,
      default: false,
      required: false
    },
    isDraft: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['update:value'],
  setup(props: any, ctx: any) {
    const { getUser, getProgramDoc } = useUserGetters(['getUser', 'getProgramDoc']);
    const programDoc = computed({
      get: () => getProgramDoc.value,
      set: newVal => {
        ctx.emit('update:value', newVal);
      }
    });
    const { saveCheckoutQualification } = useUserActions(['saveCheckoutQualification']);
    const ageLimit = ref([5, 60]);
    const emailDomain = ref();
    const activityNotification = ref(false);
    const isLoading = ref();
    const rolesValue = ref([]);
    const rolesListItems = ref(['Employer', 'Student', 'Teacher', 'Parent', 'School']);
    const tokenList = ref(['Fixed', 'Range']);
    const selectedTokenList = ref('Range');
    const inputs = ref([
      { label: 'City', value: '', type: 'city', checked: false },
      { label: 'School', value: '', type: 'school', checked: false },
      { label: 'School District', value: '', type: 'schoolDistrict', checked: false }
    ]);
    const isUnsponsoredProgram = ref(false);
    const unsponsoredAdk = ref('Please Select Activity');
    const unsponsoredAdkList = ref(['Please Select Activity']);
    programDoc?.value?.adks?.forEach(adk => {
      if (adk?.status) {
        unsponsoredAdkList?.value?.push(adk?.name);
      }
    });
    const min: number = ref();
    const max: number = ref();
    const tokensForCheckout: number = ref();

    function setPlace(e, index) {
      inputs.value[index].value = '';
      if (e.target) {
        inputs.value[index].value = e.target.value;
      } else if (e.formatted_address) {
        inputs.value[index].value = e.formatted_address;
      }
    }

    const calculateRange = (min, max, inc) => {
      const range = _.range(min, max + inc, inc);
      return range.map(value => {
        if (value % inc === 0) {
          return value;
        }
        return '';
      });
    };

    const containerRef = ref(null); // Add the ref for the container

    onMounted(() => {
      if (props.openCheckoutView) {
        if (containerRef.value) {
          setTimeout(() => {
            containerRef.value.scrollTop = containerRef.value.scrollHeight;
          }, 500);
          ctx.emit('updateCheckoutView', 'updateCheckoutView');
        }
      }
    });

    const getDocData = async () => {
      const document = getProgramDoc.value;
      activityNotification.value = document?.activityNotification || false;
      ageLimit.value = document?.checkoutQualification?.ageLimit || [5, 60];
      emailDomain.value = document?.checkoutQualification?.emailDomain;
      if (document?.checkoutQualification?.city) {
        const cityInputIndex = inputs?.value?.findIndex(i => i.type === 'city');
        inputs.value[cityInputIndex].value = document?.checkoutQualification?.city;
        inputs.value[cityInputIndex].checked = true;
      }
      if (document?.checkoutQualification?.school) {
        const schoolInputIndex = inputs?.value?.findIndex(i => i.type === 'school');
        inputs.value[schoolInputIndex].value = document?.checkoutQualification?.school;
        inputs.value[schoolInputIndex].checked = true;
      }
      if (document?.checkoutQualification?.schoolDistrict) {
        const schoolDistrictInputIndex = inputs?.value?.findIndex(i => i.type === 'schoolDistrict');
        inputs.value[schoolDistrictInputIndex].value =
          document?.checkoutQualification?.schoolDistrict;
        inputs.value[schoolDistrictInputIndex].checked = true;
      }
      rolesValue.value = document?.checkoutQualification?.roles;
      if (document?.isUnsponsoredProgram) {
        isUnsponsoredProgram.value = document?.isUnsponsoredProgram;
        unsponsoredAdk.value = document?.unsponsoredAdk;
      }
      if (props?.isTemplate && document?.tokensRange) {
        selectedTokenList.value = 'Range';
        min.value = document?.tokensRange?.min;
        max.value = document?.tokensRange?.max;
      } else if (props?.isTemplate && document?.tokens !== undefined && document?.tokens !== null) {
        selectedTokenList.value = 'Fixed';
        tokensForCheckout.value = document?.tokens;
      } else if (
        !props?.isTemplate &&
        document?.tokens !== undefined &&
        document?.tokens !== null
      ) {
        tokensForCheckout.value = document?.tokens;
      }
    };

    getDocData();

    const saveSettings = async () => {
      isLoading.value = true;
      const checkoutQualification: any = {
        ageLimit: ageLimit.value,
        emailDomain: emailDomain.value,
        roles: rolesValue.value
      };
      Array.from(inputs.value).forEach(item => {
        if (item?.value) {
          checkoutQualification[item?.type] = item?.value;
        }
      });
      let document_type = props?.isTemplate ? 'ProgramSchema' : 'Program';
      if (props?.isDraft) {
        document_type = 'ProgramRevision';
      }
      const payload: any = {
        document_type,
        document_id: props?.programId,
        checkout_qualification: checkoutQualification,
        activity_notification: activityNotification.value
      };
      if (isUnsponsoredProgram?.value) {
        payload.is_unsponsored_program = isUnsponsoredProgram?.value;
        payload.unsponsored_adk = unsponsoredAdk?.value;
      }
      if (props?.isTemplate) {
        if (selectedTokenList?.value === 'Fixed') {
          payload.tokens = tokensForCheckout?.value;
        } else {
          payload.tokens_range = { min: min?.value, max: max?.value };
        }
      } else {
        payload.tokens = tokensForCheckout?.value;
      }
      const response = await saveCheckoutQualification(payload);
      if (response) {
        props.fetchProgram();
        setTimeout(() => {
          isLoading.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: response?.message,
            type: 'success',
            isShowSnackbar: true
          });
        }, 2000);
      }
    };
    const rules = {
      tokenRules: value => {
        if (value && (Number.isNaN(Number(value)) || Number(value) < 0)) {
          return 'Please enter a non-negative number.';
        }
        return true;
      },
      minMaxRules: () => {
        if (!props.isTemplate) {
          const minRange = Number(programDoc?.value?.tokensRange?.min);
          const maxRange = Number(programDoc?.value?.tokensRange?.max);
          const tokens = Number(tokensForCheckout.value);
          if (
            programDoc?.value?.tokensRange?.min === undefined ||
            programDoc?.value?.tokensRange?.max === undefined ||
            (tokens !== undefined && tokens >= minRange && tokens <= maxRange)
          ) {
            return true;
          }
          return 'Tokens must be between the defined range.';
        }
        if (min?.value !== '' && max?.value !== '' && Number(min?.value) > Number(max?.value)) {
          return 'Minimum value cannot be greater than the maximum value.';
        }
        return true;
      }
    };

    const hasValidationErrors = computed(() => {
      if (!props.isTemplate) {
        const minRange = Number(programDoc?.value?.tokensRange?.min);
        const maxRange = Number(programDoc?.value?.tokensRange?.max);
        const tokens = Number(tokensForCheckout.value);
        if (
          programDoc?.value?.tokensRange?.min === undefined ||
          programDoc?.value?.tokensRange?.max === undefined ||
          (tokens !== undefined &&
            tokens >= minRange &&
            tokens <= maxRange &&
            rules.tokenRules(tokensForCheckout.value) !== 'Please enter a non-negative number.')
        ) {
          return true;
        }
        return false;
      }
      if (selectedTokenList?.value === 'Range') {
        if (
          rules.tokenRules(min?.value) === 'Please enter a non-negative number.' ||
          rules.tokenRules(max?.value) === 'Please enter a non-negative number.'
        ) {
          return false;
        }
        if (min?.value > max?.value) {
          return false;
        }
        return true;
      }
      if (rules.tokenRules(tokensForCheckout.value) === 'Please enter a non-negative number.') {
        return false;
      }
      return true;
    });

    return {
      ageLimit,
      emailDomain,
      activityNotification,
      setPlace,
      inputs,
      calculateRange,
      saveSettings,
      isLoading,
      rolesValue,
      rolesListItems,
      programDoc,
      rules,
      hasValidationErrors,
      containerRef,
      tokenList,
      selectedTokenList,
      min,
      max,
      isUnsponsoredProgram,
      unsponsoredAdk,
      unsponsoredAdkList,
      tokensForCheckout
    };
  }
});
