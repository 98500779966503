var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-edit pa-5",staticStyle:{"border":"10px solid orange","max-height":"88vh"}},[_c('v-flex',{ref:"containerRef",staticClass:"d-flex justify-center main_timeline_bar"},[_c('validation-observer',{staticClass:"module-edit__container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"headline d-flex justify-center font-weight-bold mb-6"},[_vm._v("Get started")]),_c('v-range-slider',{staticClass:"mt-10 mb-5",staticStyle:{"max-height":"100px"},attrs:{"tick-labels":_vm.calculateRange(5, 60, 5),"label":"Age","min":"5","max":"60","step":"5","tick-size":"5"},model:{value:(_vm.ageLimit),callback:function ($$v) {_vm.ageLimit=$$v},expression:"ageLimit"}}),_vm._l((_vm.inputs),function(input,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('v-checkbox',{model:{value:(input.checked),callback:function ($$v) {_vm.$set(input, "checked", $$v)},expression:"input.checked"}}),_c('validation-provider',{attrs:{"rules":input.checked ? "required" : "","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticClass:"col-12",staticStyle:{"margin-bottom":"25px"},attrs:{"is-rounded":true,"readonly":!input.checked,"error-messages":errors,"placeholder":'Enter ' + input.label.toLowerCase(),"is-renderer":true,"type":input.type},on:{"input":function($event){return _vm.setPlace($event, index)}},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"outlined":"","label":"Enter valid email","prepend-icon":"mdi-at","rounded":""},model:{value:(_vm.emailDomain),callback:function ($$v) {_vm.emailDomain=$$v},expression:"emailDomain"}})],1),[_c('v-container',{attrs:{"fluid":""}},[_c('v-select',{attrs:{"items":_vm.rolesListItems,"outlined":"","rounded":"","label":"Select Roles","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])])]}}],null,true),model:{value:(_vm.rolesValue),callback:function ($$v) {_vm.rolesValue=$$v},expression:"rolesValue"}})],1)],_c('v-checkbox',{attrs:{"large":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"ml-3"},[_vm._v("Turn on and off notification/reminder for activities.")])]},proxy:true}],null,true),model:{value:(_vm.activityNotification),callback:function ($$v) {_vm.activityNotification=$$v},expression:"activityNotification"}}),_c('div',{staticClass:"headline d-flex justify-center font-weight-bold mt-8 mb-4"},[_vm._v(" Unsponsored Program & Unsponsored Activity ")]),_c('div',[_c('v-checkbox',{attrs:{"label":"Is Program Unsponsored?","value":_vm.isUnsponsoredProgram},model:{value:(_vm.isUnsponsoredProgram),callback:function ($$v) {_vm.isUnsponsoredProgram=$$v},expression:"isUnsponsoredProgram"}}),_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-select',{attrs:{"items":_vm.unsponsoredAdkList,"disabled":!_vm.isUnsponsoredProgram,"outlined":"","hide-details":"","rounded":""},model:{value:(_vm.unsponsoredAdk),callback:function ($$v) {_vm.unsponsoredAdk=$$v},expression:"unsponsoredAdk"}}),_c('p',{staticStyle:{"color":"red","font-size":"35px","margin":"8px"}},[_vm._v("*")])],1)])],1),_c('div',{staticClass:"headline d-flex justify-center font-weight-bold mt-8 mb-4"},[_vm._v(" Tokens for checkout ")]),(_vm.programDoc && !_vm.isTemplate)?_c('div',[(
            _vm.programDoc &&
            _vm.programDoc.tokensRange &&
            _vm.programDoc.tokensRange.min !== undefined &&
            _vm.programDoc.tokensRange.max !== undefined
          )?_c('div',{staticClass:"ml-6"},[_c('h3',{},[_vm._v(" Add tokens between "+_vm._s(_vm.programDoc.tokensRange.min)+" to "+_vm._s(_vm.programDoc.tokensRange.max)+" ")])]):_vm._e(),_c('div',{staticClass:"col-6 mt-4"},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-text-field',{attrs:{"error-messages":errors,"rules":[_vm.rules.tokenRules, _vm.rules.minMaxRules],"outlined":"","label":"Token","rounded":""},model:{value:(_vm.tokensForCheckout),callback:function ($$v) {_vm.tokensForCheckout=_vm._n($$v)},expression:"tokensForCheckout"}}),_c('p',{staticStyle:{"color":"red","font-size":"35px","margin":"8px"}},[_vm._v("*")])],1)]}}],null,true)})],1)]):_c('div',[_c('div',{staticClass:"col-6"},[_c('v-select',{attrs:{"items":_vm.tokenList,"outlined":"","hide-details":"","rounded":""},model:{value:(_vm.selectedTokenList),callback:function ($$v) {_vm.selectedTokenList=$$v},expression:"selectedTokenList"}})],1),(_vm.selectedTokenList === 'Range')?_c('div',[_c('div',{staticClass:"d-flex flex-row col-12"},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-2",attrs:{"error-messages":errors,"rules":[_vm.rules.tokenRules, _vm.rules.minMaxRules],"outlined":"","label":"Minimum Tokens","rounded":""},model:{value:(_vm.min),callback:function ($$v) {_vm.min=_vm._n($$v)},expression:"min"}})]}}],null,false,562972346)}),_c('p',{staticStyle:{"color":"red","font-size":"35px","margin":"8px"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"rules":[_vm.rules.tokenRules, _vm.rules.minMaxRules],"outlined":"","label":"Maximum Tokens","rounded":""},model:{value:(_vm.max),callback:function ($$v) {_vm.max=_vm._n($$v)},expression:"max"}})]}}],null,false,3901469338)}),_c('p',{staticStyle:{"color":"red","font-size":"35px","margin":"8px"}},[_vm._v("*")])],1),_c('span',{staticClass:"text-muted ml-5"},[_vm._v("Add token like 1 to 3")])]):(_vm.selectedTokenList === 'Fixed')?_c('div',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-text-field',{attrs:{"error-messages":errors,"rules":[_vm.rules.tokenRules],"outlined":"","label":"Token","rounded":""},model:{value:(_vm.tokensForCheckout),callback:function ($$v) {_vm.tokensForCheckout=_vm._n($$v)},expression:"tokensForCheckout"}}),_c('p',{staticStyle:{"color":"red","font-size":"35px","margin":"8px"}},[_vm._v("*")])],1)]}}])}),_c('span',{staticClass:"text-muted ml-2 mt-2"},[_vm._v("Add token like 1, 2, 3, 4, n...")])],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-center mt-6"},[_c('v-btn',{staticClass:"font-weight-bold setup-adk-start white--text mr-2",attrs:{"depressed":"","x-large":"","rounded":"","color":"black"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"font-weight-bold setup-adk-start white--text",attrs:{"disabled":invalid ||
            !_vm.hasValidationErrors ||
            (_vm.isUnsponsoredProgram && _vm.unsponsoredAdk === 'Please Select Activity') ||
            (_vm.isUnsponsoredProgram &&
              _vm.unsponsoredAdkList.length >= 1 &&
              !_vm.unsponsoredAdkList.includes(_vm.unsponsoredAdk)),"color":"black","rounded":"","x-large":"","depressed":"","loading":_vm.isLoading},on:{"click":_vm.saveSettings}},[_vm._v("Save Settings")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }